/* ================================================== WARNING ================================================== */
/* ================================================== WARNING ================================================== */
/* ================================================== WARNING ================================================== */

/* This file is also deployed to FWK-SHELL, so it must contain only classes prefixed with MDM */

/* ================================================== WARNING ================================================== */
/* ================================================== WARNING ================================================== */
/* ================================================== WARNING ================================================== */

/* CSS for z-index modal button tooltip */
.mdm-section-modal-tooltip {
  z-index: 1200 !important;
}

/* CSS for displaying dropdown options above a modal */
.mdm-dropdown-options-over-modal {
  z-index: 1000000 !important;
  /* position: absolute !important; */
}

/* typo - CSS for displaying dropdown options above a modal */
.mdm-dropdow-options-over-modal {
  z-index: 1000000 !important;
  /* position: absolute !important; */
}
