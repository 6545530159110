 @import "~local-fwk-common/css-fwk/css/fonts.css";
 @import "~bootstrap/dist/css/bootstrap.min.css";
 @import "~local-fwk-common/css-fwk/css/bootstrap-extend.css";
 @import "~ionicons/css/ionicons.min.css";

 @import "~icheck/skins/all.css";

 @import "~local-fwk-common/css-fwk/css/master_style.css";
 @import "~local-fwk-common/css-fwk/css/skins/_all-skins.css";
 @import "~local-fwk-common/css-fwk/css/callouts.css";
 @import "~local-fwk-common/css-fwk/css/cdns.css";

 @import "~font-awesome/css/font-awesome.min.css";
 @import "~primeng/resources/primeng.css";
 @import "~primeng/resources/themes/saga-blue/theme.css";
 @import "~primeicons/primeicons.css";
 @import "~ngx-toastr/toastr.css";

 @import "~local-fwk-common/css-fwk/css/editor_style.css";
 @import "~ngx-bootstrap/datepicker/bs-datepicker.css";
 //@import "./scheduler.light";
 @import "./picker.min";
 @import "./default.theme";
 @import "./fsm-dashboard-popover";

 @import "./styles-menu";

 @import "@bento/styles/assets/styles/_global-styles.scss";
 @import '@bento/controls/styles/bento-buttons.scss';
 @import '@bento/controls/styles/bento-checkbox.scss';
 @import '@bento/controls/styles/bento-date-time.scss';
 @import '@bento/controls/styles/bento-fieldset.scss';
 @import '@bento/controls/styles/bento-number.scss';
 @import '@bento/controls/styles/bento-radio.scss';
 @import '@bento/controls/styles/bento-select.scss';
 @import '@bento/controls/styles/bento-switch.scss';
 @import '@bento/controls/styles/bento-tabs.scss';
 @import '@bento/controls/styles/bento-text.scss';
 @import '@bento/controls/styles/bento-text.scss';
 @import '@bento/controls/styles/bento-textarea.scss';
 @import '~jsoneditor/dist/jsoneditor.min.css';

 @import "./mdm_browser_modal_style.scss";
 @import "./mdm_other_styles.scss";
 

 .skin-blue .content {
  background: #FFFFFF;
}

.ace_text-layer .ace_line,
.ace_text-layer .ace_line *{
  font-family: consolas, menlo, monaco, "Ubuntu Mono", source-code-pro, monospace !important;
}

 
 /* You can add global styles to this file, and also import other style files */

 pre {
   white-space: pre-wrap;
   /* Since CSS 2.1 */
   white-space: -moz-pre-wrap;
   /* Mozilla, since 1999 */
   white-space: -pre-wrap;
   /* Opera 4-6 */
   white-space: -o-pre-wrap;
   /* Opera 7 */
   word-wrap: break-word;
   /* Internet Explorer 5.5+ */
 }

 /* Reset browser responsiveness default behaviour */
 @media (max-width: 1540px) {
   .table-responsive.table-no-filters {
     display: table;
   }

   .table-responsive {
     display: table;
   }
 }

 /* set app-specific browser behaviour, can be set differently for each browser-table-BROWSER_CONFIG_NAME, or for all */
 @media (max-width: 650px) {
   .table-responsive.table-no-filters {
     display: inline-block;
   }

   .table-responsive {
     display: table;
   }
 }

 .inactivity-timer-container {
   position: fixed;
   width: 100%;
   background-color: #f2a654;
   color: white;
   z-index: 1000;
 }

 .inactivity-timer-message {
   padding-left: 15px;
   padding-top: 13px;
   padding-right: 5px;
 }

 .ui-datepicker {
   z-index: 100000 !important;
 }

 .ui-widget:disabled {
   opacity: 0.7;
 }

 .ui-state-disabled {
   opacity: 0.7;
 }

 h4 {
   font-size: 16px;
 }

 .editor-list-details {
   margin-top: 20px;
   margin-left: 13px;
 }

 .col-form-label.col-form-value {
   margin-left: -10px;
 }

 .col-form-signature {
   margin-left: 0;
   padding-left: 0;
 }

 .full-screen {
   width: auto;
   height: 800px;
 }

 .slider-left-btn {
   position: absolute;
   left: 20px;
   top: 50%;
 }

 .slider-right-btn {
   position: absolute;
   right: 20px;
   top: 50%;
 }

 .ui-growl {
   top: 60px;
   z-index: 5000 !important;
 }

 .dialog-header {
   padding: 1em 1.5em;
   position: relative;
   border: 0;
   background: linear-gradient(to bottom, #f6f7f9 0%, #ebedf0 100%);
 }

 .dialog-title {
   font-size: 1.25em;
   color: black;
 }

 .dialog-body {
   padding: 1em 1.5em;
   position: relative;
   border: 0;
   background: white;
   overflow: auto;
   zoom: 1;
 }

 .dialog-footer {
   border: 1px solid #d5d5d5;
   background: #ffffff;
   color: #222222;
   padding: 1em;
   text-align: right;
   display: block;
   font-size: 1em;
 }

 .container.inner-container {
   padding-left: 0;
 }

 h3.text-info:focus,
 h3.text-info:hover {
   color: #117a8b !important;
 }

 .centered-dropdown > div > label {
   margin-top: -2px;
   /* padding-top: 0px;
  padding-bottom: 0px; */
 }

 .centered-dropdown > div > div > span {
   margin-top: 10px !important;
   /* padding-top: 0px;
   padding-bottom: 0px; */
 }

 /* h3.text-info:focus,
 h3.text-info:hover {
   color: #117a8b !important;
 } */

 .calendar-filter-dropdown {
   margin: 0;
   padding: 0;
 }

 .row.calendar-filter {
   margin-left: 0px;
   margin-right: 0px;
 }

 .cal-event.cal-event-sa {
   border-radius: 50%;
 }

 .cal-event.cal-event-visit {
   border-radius: 0%;
 }

 .cal-event.cal-event-sn {
   border-radius: 0%;
   width: 0;
   height: 0;

   background-color: transparent !important;

   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-bottom: 10px solid #555;
 }

 .header-container a {
   float: left;
 }

 .header-container h1 {
   margin-left: 20px;
   line-height: 51px;
 }

 .content {
   padding: 0px;
 }

 .sidebar-collapse .toggle-icon i {
   transform: rotate(0deg) !important;
 }

 .sidebar-collapse .logo-lg,
 .sidebar-collapse .logo-min {
   display: none !important;
 }

 .sidebar-collapse .menu-collapse .link-name,
 .sidebar-collapse .menu-collapse .fa-angle-down {
   opacity: 0;
   width: 0;
   height: 0;
 }

 .sidebar-collapse .menu-collapse .list-group-item {
   padding: 13px;
 }

 .sidebar-collapse .menu-collapse .list-group-item .link-name {
   height: 0 !important;
 }

 .sidebar-collapse .sidebar-wrapper .bottom-sidebar {
   padding: 5px !important;
 }

 .sidebar-collapse .sidebar-wrapper .menu-collapse .collapse.show {
   opacity: 0;
   height: 0;
 }

 bs-datepicker-container,
 bs-daterangepicker-container {
   z-index: 10800;
 }

 .mat-dialog-container {
   box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024,
     0 9px 46px 8px #0000001f;
   background: white;
   color: #000000de;
 }

 .fwk-shell-hidden-scripts {
   visibility: collapse;
 }

 owl-date-time-container,
 .owl-dt-container,
 .cdk-overlay-container {
   z-index: 99999999 !important;
 }

 /* ------------------------------------------------------------------
     FIX scheduler tooltip in plugins Z-INDEX
 ------------------------------------------------------------------ */
 .b-float-root {
   z-index: 10000;
 }

 .b-float-root > .b-floating {
   z-index: 10001;
 }

 bs-tooltip-container.bento-tooltip {
   z-index: 999999991 !important;
 }

 .bento-popover.popover {
   max-width: none !important;
 }

 .bento-popover > .popover-body {
   padding: 0 !important;
 }

 .bento-custom-modal > .mat-dialog-container {
   padding: 5px !important;
 }



/* navbar-colors-START */
/* CSS-uri aduse din componenta de meniu pentru a putea face restilizare/recolorare din master_style.css */

.sidebar-wrapper .bottom-sidebar .actions i {
  color: #a3a9bf;
}

.sidebar-wrapper .bottom-sidebar .usr .usr-info small.user-mail {
  color: #7983a1;
}

.sidebar-wrapper .bottom-sidebar .usr .usr-info small.user-name {
  color: white;
}

.sidebar-wrapper .bottom-sidebar {
  background: #3b465e;
}

.menu-collapse .list-group-item .menu-item-data .link-name {
  color: rgb(231, 231, 231);
}

.menu-collapse [id^="menu"]::before {
  background: #a3a9bf;
}

/* navbar-colors-END */
