/* ================================================== WARNING ================================================== */
/* ================================================== WARNING ================================================== */
/* ================================================== WARNING ================================================== */

/* This file is also deployed to FWK-SHELL, so it must contain only classes prefixed with MDM */

/* ================================================== WARNING ================================================== */
/* ================================================== WARNING ================================================== */
/* ================================================== WARNING ================================================== */

/* css for modal that fills 90% of screen */
.mdm-browser-modal-dialog-size-auto {
  min-width: 700px;
  max-width: 90%;
  min-height: 600px;
}

/* css for modal that fills 90% of screen */
.mdm-browser-modal-dialog-size-full {
  min-width: 90%;
  max-width: 90%;
  min-height: 600px;
}

/* css for modal that is small and has fixed size */
.mdm-browser-modal-dialog-size-xtra-small {
  width: 800px;
  min-height: 100px;
}

/* css for modal that is small and has fixed size */
.mdm-browser-modal-dialog-size-small {
  width: 800px;
  min-height: 210px;
}

.mdm-browser-modal-dialog-label {
  margin-bottom: auto;
  margin-top: auto;
  line-height: 40px;
  font-size: 1.2em;
}

.mdm-browser-modal-dialog-error-message {
  /* margin-top: 15px;
  margin-left: 115px; */
  color: #dc3545;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 2px;
}

.mdm-browser-modal-dialog-file-picker-container {
  padding: 0px;
  display: block;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  position: relative;

  width: 100%;
  cursor: pointer !important;
  /* pointer-events: none; */
}

.mdm-browser-modal-dialog-file-picker {
  display: block;
  position: relative;
}

.mdm-browser-modal-dialog-file-picker > input[type='file'] {
  line-height: unset;
  height: 100%;
  width: 100%;
  opacity: 0;

  /* background-color: #f1f1f1;
  color: #f1f1f1; */

  position: absolute;
  cursor: pointer !important;
}

.mdm-browser-modal-dialog-file-picker > input[type='file']::-webkit-file-upload-button {
  display: none;
  cursor: pointer !important;
}
.mdm-browser-modal-dialog-file-picker > input[type='file']::file-selector-button {
  display: none;
  cursor: pointer !important;
}

.mdm-browser-modal-dialog-file-picker-text {
  line-height: 45px;
  padding-left: 28px;
  padding-right: 28px;
  display: block;
}

.mdm-browser-modal-dialog-file-picker-container :hover {
  background-color: #f1f1f1;
  border-radius: 5px;
}

.mdm-browser-modal-dialog-file-picker-container.is-invalid {
  border-color: #dc3545;
  color: #dc3545;
}

/* css for any browser modal content */
.mdm-browser-modal-dialog-content,
.mdm-browser-modal-dialog-footer {
  display: block;
  /* padding-top: 5px; */
  margin-top: 5px;
  font-family: 'AvenirLTStd-Medium';
}

.mdm-browser-modal-dialog-footer button {
  margin-right: 0px;
}

.mdm-browser-modal-dialog-content {
  flex-grow: 1;
}

.mdm-browser-modal-dialog-footer {
  margin-bottom: -5px;
  display: flex;
  gap: 0.5rem;
}

.mdm-browser-modal-dialog-error-message {
  /* margin-top: 15px;
  margin-left: 115px; */
  color: #dc3545;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 2px;
}

.mdm-browser-modal-dialog-tooltip {
  z-index: /*2101*/ 100000000 !important; /* cineva a pus z-index la modala in fwk-shell 99999999 */
}

/* ============================================================================================================== */
/* ====================================== iOSAppAddModalContainerComponent ====================================== */
/* ============================================================================================================== */

.mdm-iOS-app-add-modal-custom-size {
  position: relative;
  min-width: 800px;
  min-height: 150px;
}

.mdm-iOS-app-add-modal-custom-size h2 {
  margin: 0;
}

.mdm-iOS-app-add-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mdm-iOS-app-add-modal-close-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 50%;
  outline: none;
  box-sizing: border-box;
  padding: 0;
  width: 32px;
  height: 32px;
}

.mdm-iOS-app-add-modal-close-button:focus {
  outline: none;
}

.mdm-iOS-app-add-modal-close-button:hover {
  background-color: #e9ecef;
}

.mdm-iOS-app-add-modal-close-button:active {
  background-color: #e9ecef;
}

.mdm-iOS-app-add-modal-close-button mat-icon {
  color: #6c757d;
  width: 2rem;
  height: 2rem;
  margin: 0;
}

.mdm-iOS-app-add-modal-editor {
  padding-top: 5px;
}

.mdm-iOS-app-add-modal-editor-input {
  width: 90.5%;
}

.mdm-iOS-app-add-modal-application-list {
  display: flex;
  flex-direction: column;
}

.mdm-iOS-app-add-modal-application-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eff1f3;
  /* border: 1px solid #d1d5db;  */
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.mdm-iOS-app-add-modal-card-content {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
}

.mdm-iOS-app-add-modal-icon-content {
  margin-right: 10px;
}

.mdm-iOS-app-add-modal-app-icon {
  width: 60px;
  height: 60px;
  border-radius: 8px;
}

.mdm-iOS-app-add-modal-app-info {
  flex-grow: 1;
  padding-left: 10px;
  margin: 0;
}

.mdm-iOS-app-add-modal-app-info h3,
.mdm-iOS-app-add-modal-app-info p,
.mdm-iOS-app-add-modal-app-info small {
  margin-top: 2px;
  margin-right: 0;
  padding: 0;
}

.mdm-iOS-app-add-modal-add-button {
  margin-left: 10px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.mdm-iOS-app-add-modal-loading-overlay {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mdm-iOS-app-add-modal-loading-spinner {
  color: #6c757d;
  font-size: 50px;
}

.mdm-iOS-app-add-modal-standard-button-height {
  min-height: 36px;
}

.mdm-iOS-app-add-modal-standard-button-height.btn {
  height: unset;
  line-height: unset;
  border-radius: 0.25rem;
  padding-top: 3px;
}
