/* ------------------------------------------------------------------
    dashboard popovers
------------------------------------------------------------------ */

.popover,
.popover.popover-content {
  /* max-width: 180px; */
  font-size: 1rem;
}

.popover.woPopup,
.popover.woPopup .popover-content {
  max-width: 430px;
  width: 430px;
  padding: 0;
  border-radius: 10px;
}

.popover.employeeState,
.popover.employeeState .popover-content {
  max-width: 132px;
  max-height: 96px;
  border-radius: 10px;
  /* top: -30px; */
  padding: 5px;
}

.popover.employeeState .popover-arrow {
  display: none;
}

.popover.resourcePopup,
.popover.resourcePopup .popover-content {
  max-width: 400px;
  padding: 6px;
  border-radius: 10px;
}

.popover-content .person {
  /* padding: 16px;
  border-bottom: 1px solid #f5f5f8; */
  padding: 0;
  font-weight: 400;
  font-family: 'AvenirLTStd-Medium';
}

.popover-content .person .profile {
  width: 52px;
  height: 100%;
  float: left;
  margin-right: 12px;
}

.popover-content .person .profile div:nth-of-type(1) {
  background: url('/assets/images/employee.png') no-repeat scroll center;
  height: 52px;
  width: 52px;
  border-radius: 26px;
}

.popover-content .person .profile div.team:nth-of-type(1) {
  background: url('/assets/images/team.png') no-repeat scroll center;
}

.popover-content .person .profile .status-online-container {
  height: 25px;
  width: 100%;
  margin-top: 13px;
}

.popover-content .person .profile .status-online-container div.status-online {
  background: url('/assets/images/red_circle.png') no-repeat center;
  height: 25px;
  width: 100%;
}

.popover-content .person .details {
  float: left;
  width: 223px;
}

.popover-content .person .details .name {
  font-size: 1.125rem;
  height: 52px;
  line-height: 52px;
  width: 225px;
  position: relative;
  float: left;
}

.popover-content .person .details .name div:nth-of-type(1) {
  float: left;
}

.popover-content .person .details .name .status {
  position: absolute;
  right: 0;
  height: 13px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.popover-content .person .details .name .status.inexecutie {
  right: 15px;
  width: 13px;
  background: url('/assets/images/inexecutie.png') no-repeat center center;
}

.popover-content .person .details .name .status.indeplasare {
  right: 15px;
  width: 13px;
  background: url('/assets/images/car.png') no-repeat center center;
}

.popover-content .person .details .name .status.disponibil {
  right: 15px;
  width: 13px;
  height: 13px;
  background: url('/assets/images/ready.png') no-repeat center center;
}

.popover-content .person .details .name .status.indisponibil {
  right: 15px;
  width: 13px;
  height: 13px;
  background: url('/assets/images/indisponibil.png') no-repeat center center;
}

.popover-content .person .details .name .status.faratura {
  right: 15px;
  width: 13px;
  height: 13px;
  background: url('/assets/images/noshift.png') no-repeat center center;
}

.popover-content .person .details .name .status.pauza {
  right: 15px;
  width: 13px;
  background: url('/assets/images/inpauza.png') no-repeat center center;
}

.popover-content .person .details .name .status.offline {
  background: url('/assets/images/offline.png') no-repeat right center;
  padding-right: 15px;
  font-size: 10px;
  color: #b0bed6;
  line-height: 15px;
  text-transform: uppercase;
}

.popover-content .person .details .team {
  margin-bottom: 15px;
  margin-top: -5px;
}

.popover-content .person .details .team .member {
  height: 25px;
  border-radius: 10px;
  background-color: #b0bed6;
  color: #ffffff;
  margin-left: 4px;
  margin-bottom: 4px;
  float: left;
  line-height: 25px;
  padding: 0 12px;
  font-size: 12px;
}

.popover-content .person .details .team .member:first-child {
  margin-left: 0;
}

.popover-content .person .details .skills,
.popover-content .person .details .tools {
  margin-left: 18px;
  font-size: 12px;
  line-height: 22px;
  color: #b0bed6;
  padding-left: 15px;
}

.popover-content .person .details .skills {
  background: url('/assets/images/profession.png') no-repeat left top;
  background-position-y: 5px;
}

.popover-content .person .details .tools {
  background: url('/assets/images/tools.png') no-repeat left top;
  background-position-y: 5px;
}
