/* === BEGIN default menu settings BEGIN === */
/* .level-1 {
} */

/* .level-2 {
  margin-left: 15px;
}

.level-3 {
  margin-left: 30px;
}

.level-4 {
  margin-left: 45px;
} */

.menu-collapse {
  overflow-y: auto;
  height: 1174px;
}

.list-group-item {
  background-color: #7983a1;
  border: none;
  color: #afb7ce;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
}

.list-group-item:hover {
  color: white;
}

.list-group-item:focus {
  color: #afb7ce;
}

.list-group-item.active {
  background-color: #7983a1 !important;
}

.list-group-item > .menu-item-data {
  text-align: center;
}

.list-group-item > .menu-item-data > .link-name {
  text-align: left;
}

/* default is menu-icon-spacing-5 */
.list-group-item > .menu-item-data > span {
  margin-right: 5px;
}

/* default is menu-icon-min-width-none */
.list-group-item > .menu-item-data > span {
  min-width: none;
}
/* === END default menu settings END === */

/* === ALLOWED CSS CONFIGURATIONS === */

/* All implemented options: */
/* menu-icon-spacing-X, where X in {3,4,5,8,10} */
/* menu-icon-min-width-X, where X between 5 and 20 */

/* === ALLOWED CSS CONFIGURATIONS IMPLEMENTATIONS === */

/* menu-icon-spacing-X, where X in {3,4,5,8,10} */
.list-group-item > .menu-item-data > span.menu-icon-spacing-3 {
  margin-right: 3px;
}

.list-group-item > .menu-item-data > span.menu-icon-spacing-4 {
  margin-right: 4px;
}

.list-group-item > .menu-item-data > span.menu-icon-spacing-5 {
  margin-right: 5px;
}

.list-group-item > .menu-item-data > span.menu-icon-spacing-8 {
  margin-right: 8px;
}

.list-group-item > .menu-item-data > span.menu-icon-spacing-10 {
  margin-right: 10px;
}

/* menu-icon-min-width-X, where X between 5 and 20 */
.list-group-item > .menu-item-data > span.menu-icon-min-width-5 {
  min-width: 5px;
}

.list-group-item > .menu-item-data > span.menu-icon-min-width-6 {
  min-width: 6px;
}

.list-group-item > .menu-item-data > span.menu-icon-min-width-7 {
  min-width: 7px;
}

.list-group-item > .menu-item-data > span.menu-icon-min-width-8 {
  min-width: 8px;
}

.list-group-item > .menu-item-data > span.menu-icon-min-width-9 {
  min-width: 9px;
}

.list-group-item > .menu-item-data > span.menu-icon-min-width-10 {
  min-width: 10px;
}

.list-group-item > .menu-item-data > span.menu-icon-min-width-11 {
  min-width: 11px;
}

.list-group-item > .menu-item-data > span.menu-icon-min-width-12 {
  min-width: 12px;
}

.list-group-item > .menu-item-data > span.menu-icon-min-width-13 {
  min-width: 13px;
}

.list-group-item > .menu-item-data > span.menu-icon-min-width-14 {
  min-width: 14px;
}

.list-group-item > .menu-item-data > span.menu-icon-min-width-15 {
  min-width: 15px;
}

.list-group-item > .menu-item-data > span.menu-icon-min-width-16 {
  min-width: 16px;
}

.list-group-item > .menu-item-data > span.menu-icon-min-width-17 {
  min-width: 17px;
}

.list-group-item > .menu-item-data > span.menu-icon-min-width-18 {
  min-width: 18px;
}

.list-group-item > .menu-item-data > span.menu-icon-min-width-19 {
  min-width: 19px;
}

.list-group-item > .menu-item-data > span.menu-icon-min-width-20 {
  min-width: 20px;
}


